import { EvaluationTemplate, EvaluationTemplateTeam } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function evaluationTemplate__client__editEvaluationTemplate(p: { template: EvaluationTemplate }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.EvaluationTemplate.set({ id: p.template.id, doc: _.cloneDeep(p.template) });
}

// i18n certified - complete
