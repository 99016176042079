import { EvaluationTemplateId } from "@ollie-sports/models";
import express from "express";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";

export async function evaluationTemplate__server__deleteEvaluationTemplate(p: { evaluationTemplateId: EvaluationTemplateId }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.EvaluationTemplate.delete({ id: p.evaluationTemplateId });
  // SERVER_ONLY_TOGGLE
}

evaluationTemplate__server__deleteEvaluationTemplate.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
