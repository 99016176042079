import { EvaluationTemplate, TeamId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function evaluationTemplate__client__getEvaluationTemplatesForTeamSubscription(p: { teamId: TeamId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(h.EvaluationTemplate.querySubscription({ where: [{ teamId: ["==", p.teamId] }] }));
}

// i18n certified - complete
