import { EvaluationTemplate, OrgId, TeamId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function evaluationTemplate__client__getEvaluationTemplatesForOrgSubscription(p: { orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(h.EvaluationTemplate.querySubscription({ where: [{ orgId: ["==", p.orgId] }] }));
}

// i18n certified - complete
