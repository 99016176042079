import { EvaluationTemplate, EvaluationTemplateOrg, EvaluationTemplateTeam } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function evaluationTemplate__client__addEvaluationTemplate(p: {
  evaluationTemplate: Omit<EvaluationTemplateOrg, "id" | "createdAtMS"> | Omit<EvaluationTemplateTeam, "id" | "createdAtMS">;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const id = h.EvaluationTemplate.generateId();
  const nowMS = Date.now();
  const template: EvaluationTemplate = {
    ...p.evaluationTemplate,
    ...{
      id,
      createdAtMS: nowMS
    }
  };

  await h.EvaluationTemplate.add({
    doc: _.cloneDeep(template)
  });
  return template;
}

// i18n certified - complete
